import React, { useState, useEffect } from 'react';
import Web3 from "web3";
import SupplyChainABI from "./artifacts/SupplyChain.json"
import { useHistory } from "react-router-dom"

function AssignRoles() {
    const history = useHistory()
    useEffect(() => {
        loadWeb3();
        loadBlockchaindata();
    }, [])
    const [currentaccount, setCurrentaccount] = useState("");
    const [loader, setloader] = useState(true);
    const [SupplyChain, setSupplyChain] = useState();
    
    const [FRAname, setFRAname] = useState();
    const [DISname, setDISname] = useState();
    const [RETname, setRETname] = useState();
    
    const [FRAplace, setFRAplace] = useState();
    const [DISplace, setDISplace] = useState();
    const [RETplace, setRETplace] = useState();
    
    const [FRAaddress, setFRAaddress] = useState();
    const [DISaddress, setDISaddress] = useState();
    const [RETaddress, setRETaddress] = useState();
   
    const [FRA, setFRA] = useState();
    const [DIS, setDIS] = useState();
    const [RET, setRET] = useState();

    const loadWeb3 = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert(
                "Non-Ethereum browser detected. You should consider trying MetaMask!"
            );
        }
    };

    const loadBlockchaindata = async () => {
        setloader(true);
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        setCurrentaccount(account);
        const networkId = await web3.eth.net.getId();
        const networkData = SupplyChainABI.networks[networkId];
        if (networkData) {
            const supplychain = new web3.eth.Contract(SupplyChainABI.abi, networkData.address);
            setSupplyChain(supplychain);
            var i;
            
            const fraCtr = await supplychain.methods.fraCtr().call();
            const fra = {};
            for (i = 0; i < fraCtr; i++) {
                fra[i] = await supplychain.methods.FRA(i + 1).call();
            }
            setFRA(fra);
            const disCtr = await supplychain.methods.disCtr().call();
            const dis = {};
            for (i = 0; i < disCtr; i++) {
                dis[i] = await supplychain.methods.DIS(i + 1).call();
            }
            setDIS(dis);
            const retCtr = await supplychain.methods.retCtr().call();
            const ret = {};
            for (i = 0; i < retCtr; i++) {
                ret[i] = await supplychain.methods.RET(i + 1).call();
            }
            setRET(ret);
            setloader(false);
        }
        else {
            window.alert('Lo smart contract non è stato registrato sulla rete corrente')
        }
    }
    if (loader) {
        return (
            <div>
                <h1 className="wait">Caricamento...</h1>
            </div>
        )

    }
    const redirect_to_home = () => {
        history.push('/')
    }
    
    const handlerChangeAddressFRA = (event) => {
        setFRAaddress(event.target.value);
    }
    const handlerChangePlaceFRA = (event) => {
        setFRAplace(event.target.value);
    }
    const handlerChangeNameFRA = (event) => {
        setFRAname(event.target.value);
    }
    const handlerChangeAddressDIS = (event) => {
        setDISaddress(event.target.value);
    }
    const handlerChangePlaceDIS = (event) => {
        setDISplace(event.target.value);
    }
    const handlerChangeNameDIS = (event) => {
        setDISname(event.target.value);
    }
    const handlerChangeAddressRET = (event) => {
        setRETaddress(event.target.value);
    }
    const handlerChangePlaceRET = (event) => {
        setRETplace(event.target.value);
    }
    const handlerChangeNameRET = (event) => {
        setRETname(event.target.value);
    }
 
    const handlerSubmitFRA = async (event) => {
        event.preventDefault();
        try {
            var reciept = await SupplyChain.methods.addFrantoio(FRAaddress, FRAname, FRAplace).send({ from: currentaccount });
            if (reciept) {
                loadBlockchaindata();
            }
        }
        catch (err) {
            alert("Errore!!!")
        }
    }
    const handlerSubmitDIS = async (event) => {
        event.preventDefault();
        try {
            var reciept = await SupplyChain.methods.addDistributor(DISaddress, DISname, DISplace).send({ from: currentaccount });
            if (reciept) {
                loadBlockchaindata();
            }
        }
        catch (err) {
            alert("Errore!!!")
        }
    }
    const handlerSubmitRET = async (event) => {
        event.preventDefault();
        try {
            var reciept = await SupplyChain.methods.addRetailer(RETaddress, RETname, RETplace).send({ from: currentaccount });
            if (reciept) {
                loadBlockchaindata();
            }
        }
        catch (err) {
            alert("Errore!!!")
        }
    }



    return (
        <div>
            <span><b>Indirizzo Account corrente:</b> {currentaccount} </span>
            <span onClick={redirect_to_home} className="btn btn-outline-danger btn-sm">HOME</span>
            
            <h4>Frantoi:</h4>
            <form onSubmit={handlerSubmitFRA}>
                <input className="form-control-sm" type="text" onChange={handlerChangeAddressFRA} placeholder="Indirizzo Ethereum" required />
                <input className="form-control-sm" type="text" onChange={handlerChangeNameFRA} placeholder="Nome Frantoio" required />
                <input className="form-control-sm" type="text" onChange={handlerChangePlaceFRA} placeholder="Luogo" required />
                <button className="btn btn-outline-success btn-sm" onSubmit={handlerSubmitFRA}>Registra</button>
            </form>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Luogo</th>
                        <th scope="col">Indirizzo Ethereum</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(FRA).map(function (key) {
                        return (
                            <tr key={key}>
                                <td>{FRA[key].id}</td>
                                <td>{FRA[key].name}</td>
                                <td>{FRA[key].place}</td>
                                <td>{FRA[key].addr}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <h4>Distributori:</h4>
            <form onSubmit={handlerSubmitDIS}>
                <input className="form-control-sm" type="text" onChange={handlerChangeAddressDIS} placeholder="Indirizzo Ethereum" required />
                <input className="form-control-sm" type="text" onChange={handlerChangeNameDIS} placeholder="Nome Distributore" required />
                <input className="form-control-sm" type="text" onChange={handlerChangePlaceDIS} placeholder="Luogo" required />
                <button className="btn btn-outline-success btn-sm" onSubmit={handlerSubmitDIS}>Registra</button>
            </form>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Luogo</th>
                        <th scope="col">Indirizzo Ethereum</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(DIS).map(function (key) {
                        return (
                            <tr key={key}>
                                <td>{DIS[key].id}</td>
                                <td>{DIS[key].name}</td>
                                <td>{DIS[key].place}</td>
                                <td>{DIS[key].addr}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <h4>Rivenditori:</h4>
            <form onSubmit={handlerSubmitRET}>
                <input className="form-control-sm" type="text" onChange={handlerChangeAddressRET} placeholder="Indirizzo Ethereum" required />
                <input className="form-control-sm" type="text" onChange={handlerChangeNameRET} placeholder="Nome Rivenditore" required />
                <input className="form-control-sm" type="text" onChange={handlerChangePlaceRET} placeholder="Luogo" required />
                <button className="btn btn-outline-success btn-sm" onSubmit={handlerSubmitRET}>Registra</button>
            </form>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th scope="col">ID</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Luogo</th>
                        <th scope="col">Indirizzo Ethereum</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(RET).map(function (key) {
                        return (
                            <tr key={key}>
                                <td>{RET[key].id}</td>
                                <td>{RET[key].name}</td>
                                <td>{RET[key].place}</td>
                                <td>{RET[key].addr}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default AssignRoles
