import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import Web3 from "web3";
import SupplyChainABI from "./artifacts/SupplyChain.json"

function AddProduct() {
    const history = useHistory()
    useEffect(() => {
        loadWeb3();
        loadBlockchaindata();
    }, [])

    const [currentaccount, setCurrentaccount] = useState("");
    const [loader, setloader] = useState(true);
    const [SupplyChain, setSupplyChain] = useState();
    const [PRODUCT, setPRODUCT] = useState();
    const [ProductName, setProductName] = useState();
    const [ProductDes, setProductDes] = useState();
    const [ProductStage, setProductStage] = useState();


    const loadWeb3 = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert(
                "Non-Ethereum browser detected. You should consider trying MetaMask!"
            );
        }
    };

    const loadBlockchaindata = async () => {
        setloader(true);
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        setCurrentaccount(account);
        const networkId = await web3.eth.net.getId();
        const networkData = SupplyChainABI.networks[networkId];
        if (networkData) {
            const supplychain = new web3.eth.Contract(SupplyChainABI.abi, networkData.address);
            setSupplyChain(supplychain);
            var i;
            const productCtr = await supplychain.methods.productCtr().call();
            const product = {};
            const productStage = [];
            for (i = 0; i < productCtr; i++) {
                product[i] = await supplychain.methods.ProductStock(i + 1).call();
                productStage[i] = await supplychain.methods.showStage(i + 1).call();
            }
            setPRODUCT(product);
            setProductStage(productStage);
            setloader(false);
        }
        else {
            window.alert('Lo smart contract non è stato registrato sulla rete corrente')
        }
    }
    if (loader) {
        return (
            <div>
                <h1 className="wait">Caricamento...</h1>
            </div>
        )

    }
    const redirect_to_home = () => {
        history.push('/')
    }
    const handlerChangeNamePRODUCT = (event) => {
        setProductName(event.target.value);
    }
    const handlerChangeDesPRODUCT = (event) => {
        setProductDes(event.target.value);
    }
    const handlerSubmitPRODUCT = async (event) => {
        event.preventDefault();
        try {
            var reciept = await SupplyChain.methods.addProduct(ProductName, ProductDes).send({ from: currentaccount });
            if (reciept) {
                loadBlockchaindata();
            }
        }
        catch (err) {
            alert("Errore!!!")
        }
    }
    return (
        <div>
            <span><b>Indirizzo Account corrente:</b> {currentaccount} </span>
            <span onClick={redirect_to_home} className="btn btn-outline-danger btn-sm"> HOME</span>
            <br />
            <h5>Inserisci Prodotto:</h5>
            <form onSubmit={handlerSubmitPRODUCT}>
                <input className="form-control-sm" type="text" onChange={handlerChangeNamePRODUCT} placeholder="Tipo" required />
                <input className="form-control-sm" type="text" onChange={handlerChangeDesPRODUCT} placeholder="Descrizione" required />
                <button className="btn btn-outline-success btn-sm" onSubmit={handlerSubmitPRODUCT}>Inserisci</button>
            </form>
            <br />
            <h5>Prodotti Inseriti:</h5>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">ID/Lotto</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descrizione</th>
                        <th scope="col">Stage Corrente</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(PRODUCT).map(function (key) {
                        return (
                            <tr key={key}>
                                <td>{PRODUCT[key].id}</td>
                                <td>{PRODUCT[key].name}</td>
                                <td>{PRODUCT[key].description}</td>
                                <td>
                                    {
                                        ProductStage[key]
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default AddProduct
