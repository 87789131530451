import React from 'react'
import { useHistory } from "react-router-dom"

function Home() {
    const history = useHistory()
    const redirect_to_roles = () => {
        history.push('/roles')
    }
    const redirect_to_addproduct = () => {
        history.push('/addproduct')
    }
    const redirect_to_supply = () => {
        history.push('/supply')
    }
    const redirect_to_track = () => {
        history.push('/track')
    }
    return (
        <div>
            <h3>EVO Oil Supply Chain Flow</h3>
            <br />
            <h6>(Nota: L' "<u>Owner</u>" è la figura che ha registrato lo smart contract sulla blockchain e coincide con l'ente/consorzio certificatore della filiera)</h6>
            <h5>Step 1: L'Owner deve registrare Frantoi, Distributori e Rivenditori</h5>
            <h6>(Questo va fatto solo una volta. Passa allo step 2 se è stato già fatto)</h6>
            <button onClick={redirect_to_roles} className="btn btn-outline-primary btn-sm">Registra</button>
            <br />
            <h5>Step 2: Il Frantoio inserisce i Prodotti</h5>
            <button onClick={redirect_to_addproduct} className="btn btn-outline-primary btn-sm">Inserisci Prodotti</button>
            <br />
            <h5>Step 3: Control Supply Chain</h5>
            <button onClick={redirect_to_supply} className="btn btn-outline-primary btn-sm">Control Supply Chain</button>
            <br />
            <hr />
            <br />
            <h5><b>Traccia</b> i Prodotti:</h5>
            <button onClick={redirect_to_track} className="btn btn-outline-primary btn-sm">Traccia</button>
	    <br />
            <br />
            <br />
	    <h5>Per testare la dApp, importa su Metamask questi Indirizzi Ethereum (creati su testnet Goerli) con le relative chiavi private</h5>
            <br />
	    <h5>Owner - 0xB447F98DEd7CE423c66c3f80a5278e8b302FFC67</h5>
            <h5>Frantoio - 0x391761b1cB4F3b37ace349B9AAA8CAbecba9eCc3 - Key - 426f14e1ebb6379c773c22d9012438ac7e91d6e7c33841c9884c55a6d4d37501</h5>
	    <h5>Distributore - 0x55862E0665EBb678eD9c6ec3F0EC842F0a4DAE5b - Key - ba125c1d0e658329bdbb8d90a36b18329c61421a862da35cd299c3a39c315d28</h5>
	    <h5>Rivenditore - 0x7abdC9432bae9E982E652A382CB3fF2033F57754 - Key - 67dba283b55852c6597b263c3e88f0d5f81e211c3b9a1661052adbeebbcbe295</h5>
        </div>
    )
}

export default Home
