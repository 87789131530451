import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom"
import Web3 from "web3";
import SupplyChainABI from "./artifacts/SupplyChain.json"

function Track() {
    const history = useHistory()
    useEffect(() => {
        loadWeb3();
        loadBlockchaindata();
    }, [])

    const [currentaccount, setCurrentaccount] = useState("");
    const [loader, setloader] = useState(true);
    const [SupplyChain, setSupplyChain] = useState();
    const [PRODUCT, setPRODUCT] = useState();
    const [ProductStage, setProductStage] = useState();
    const [ID, setID] = useState();
    
    const [FRA, setFRA] = useState();
    const [DIS, setDIS] = useState();
    const [RET, setRET] = useState();
    const [TrackTillSold, showTrackTillSold] = useState(false);
    const [TrackTillRetail, showTrackTillRetail] = useState(false);
    const [TrackTillDistribution, showTrackTillDistribution] = useState(false);
   
    
    const [TrackTillOrdered, showTrackTillOrdered] = useState(false);

    const loadWeb3 = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            window.alert(
                "Non-Ethereum browser detected. You should consider trying MetaMask!"
            );
        }
    };

    const loadBlockchaindata = async () => {
        setloader(true);
        const web3 = window.web3;
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];
        setCurrentaccount(account);
        const networkId = await web3.eth.net.getId();
        const networkData = SupplyChainABI.networks[networkId];
        if (networkData) {
            const supplychain = new web3.eth.Contract(SupplyChainABI.abi, networkData.address);
            setSupplyChain(supplychain);
            var i;
            const productCtr = await supplychain.methods.productCtr().call();
            const product = {};
            const productStage = [];
            for (i = 0; i < productCtr; i++) {
                product[i + 1] = await supplychain.methods.ProductStock(i + 1).call();
                productStage[i + 1] = await supplychain.methods.showStage(i + 1).call();
            }
            setPRODUCT(product);
            setProductStage(productStage);
           
            const fraCtr = await supplychain.methods.fraCtr().call();
            const fra = {};
            for (i = 0; i < fraCtr; i++) {
                fra[i + 1] = await supplychain.methods.FRA(i + 1).call();
            }
            setFRA(fra);
            const disCtr = await supplychain.methods.disCtr().call();
            const dis = {};
            for (i = 0; i < disCtr; i++) {
                dis[i + 1] = await supplychain.methods.DIS(i + 1).call();
            }
            setDIS(dis);
            const retCtr = await supplychain.methods.retCtr().call();
            const ret = {};
            for (i = 0; i < retCtr; i++) {
                ret[i + 1] = await supplychain.methods.RET(i + 1).call();
            }
            setRET(ret);
            setloader(false);
        }
        else {
            window.alert('Lo smart contract non è stato registrato sulla rete corrente')
        }
    }
    if (loader) {
        return (
            <div>
                <h1 className="wait">Caricamento...</h1>
            </div>
        )
    }
    if (TrackTillSold) {
        return (
            <div className="container-xl">
                <article className="col-4">
                    <h3><b><u>Prodotto:</u></b></h3>
                    <span><b>ID/Lotto: </b>{PRODUCT[ID].id}</span>
                    <br />
                    <span><b>Tipo:</b> {PRODUCT[ID].name}</span>
                    <br />
                    <span><b>Descrizione: </b>{PRODUCT[ID].description}</span>
                    <br />
                    <span><b>Step corrente: </b>{ProductStage[ID]}</span>
                </article>
                <hr />
                <br />
                <section className="row">

                    
                    <article className="col-3">
                        <h4><u>Molito da:</u></h4>
                        <p><b>ID Frantoio: </b>{FRA[PRODUCT[ID].FRAid].id}</p>
                        <p><b>Nome:</b> {FRA[PRODUCT[ID].FRAid].name}</p>
                        <p><b>Luogo: </b>{FRA[PRODUCT[ID].FRAid].place}</p>
                    </article>
                    <span>&#10132;</span>
                    <article className="col-3">
                        <h4><u>Distribuito da:</u></h4>
                        <p><b>ID Distributore: </b>{DIS[PRODUCT[ID].DISid].id}</p>
                        <p><b>Nome:</b> {DIS[PRODUCT[ID].DISid].name}</p>
                        <p><b>Luogo: </b>{DIS[PRODUCT[ID].DISid].place}</p>
                    </article>
                    <span>&#10132;</span>
                    <article className="col-3">
                        <h4><u>Rivenditore:</u></h4>
                        <p><b>ID Rivenditore: </b>{RET[PRODUCT[ID].RETid].id}</p>
                        <p><b>Nome:</b> {RET[PRODUCT[ID].RETid].name}</p>
                        <p><b>Luogo: </b>{RET[PRODUCT[ID].RETid].place}</p>
                    </article>
                    <span>&#10132;</span>
                    <article className="col-3">
                        <h4><u>Venduto</u></h4>
                    </article>
                </section>
                <button onClick={() => {
                    showTrackTillSold(false);
                }} className="btn btn-outline-success btn-sm">Traccia un altro prodotto</button>
                <span onClick={() => {
                    history.push('/')
                }} className="btn btn-outline-danger btn-sm"> HOME</span>
            </div >
        )
    }
    if (TrackTillRetail) {
        return (
            <div className="container-xl">
                <article className="col-4">
                    <h3><b><u>Prodotto:</u></b></h3>
                    <span><b>ID/Lotto: </b>{PRODUCT[ID].id}</span>
                    <br />
                    <span><b>Tipo:</b> {PRODUCT[ID].name}</span>
                    <br />
                    <span><b>Descrizione: </b>{PRODUCT[ID].description}</span>
                    <br />
                    <span><b>Step corrente: </b>{ProductStage[ID]}</span>
                </article>
                <hr />
                <br />
                <section className="row">

                    
                    <article className="col-3">
                        <h4><u>Molito da:</u></h4>
                        <p><b>ID Frantoio: </b>{FRA[PRODUCT[ID].FRAid].id}</p>
                        <p><b>Nome:</b> {FRA[PRODUCT[ID].FRAid].name}</p>
                        <p><b>Luogo: </b>{FRA[PRODUCT[ID].FRAid].place}</p>
                    </article>
                    <span>&#10132;</span>
                    <article className="col-3">
                        <h4><u>Distributed by:</u></h4>
                        <p><b>Distributor ID: </b>{DIS[PRODUCT[ID].DISid].id}</p>
                        <p><b>Nome:</b> {DIS[PRODUCT[ID].DISid].name}</p>
                        <p><b>Luogo: </b>{DIS[PRODUCT[ID].DISid].place}</p>
                    </article>
                    <span>&#10132;</span>
                    <article className="col-3">
                        <h4><u>Retailed by:</u></h4>
                        <p><b>Retailer ID: </b>{RET[PRODUCT[ID].RETid].id}</p>
                        <p><b>Nome:</b> {RET[PRODUCT[ID].RETid].name}</p>
                        <p><b>Luogo: </b>{RET[PRODUCT[ID].RETid].place}</p>
                    </article>
                </section>
                <button onClick={() => {
                    showTrackTillRetail(false);
                }} className="btn btn-outline-success btn-sm">Track Another Item</button>
                <span onClick={() => {
                    history.push('/')
                }} className="btn btn-outline-danger btn-sm"> HOME</span>
            </div >
        )
    }
    if (TrackTillDistribution) {
        return (
            <div className="container-xl">
                <article className="col-4">
                    <h3><b><u>Prodotto:</u></b></h3>
                    <span><b>ID/Lotto: </b>{PRODUCT[ID].id}</span>
                    <br />
                    <span><b>Tipo:</b> {PRODUCT[ID].name}</span>
                    <br />
                    <span><b>Descrizione: </b>{PRODUCT[ID].description}</span>
                    <br />
                    <span><b>Step corrente </b>{ProductStage[ID]}</span>
                </article>
                <hr />
                <br />
                <section className="row">

                    
                    <article className="col-3">
                        <h4><u>Molito da:</u></h4>
                        <p><b>ID Frantoio: </b>{FRA[PRODUCT[ID].FRAid].id}</p>
                        <p><b>Nome:</b> {FRA[PRODUCT[ID].FRAid].name}</p>
                        <p><b>Luogo: </b>{FRA[PRODUCT[ID].FRAid].place}</p>
                    </article>
                    <span>&#10132;</span>
                    <article className="col-3">
                        <h4><u>Distributed by:</u></h4>
                        <p><b>Distributor ID: </b>{DIS[PRODUCT[ID].DISid].id}</p>
                        <p><b>Nome:</b> {DIS[PRODUCT[ID].DISid].name}</p>
                        <p><b>Luogo: </b>{DIS[PRODUCT[ID].DISid].place}</p>
                    </article>
                </section>
                <button onClick={() => {
                    showTrackTillDistribution(false);
                }} className="btn btn-outline-success btn-sm">Track Another Item</button>
                <span onClick={() => {
                    history.push('/')
                }} className="btn btn-outline-danger btn-sm"> HOME</span>
            </div >
        )
    }
    
   
    if (TrackTillOrdered) {
        return (
            <div className="container-xl">
                <article className="col-4">
                    <h3><b><u>Prodotto:</u></b></h3>
                    <span><b>ID/Lotto: </b>{PRODUCT[ID].id}</span>
                    <br />
                    <span><b>Tipo:</b> {PRODUCT[ID].name}</span>
                    <br />
                    <span><b>Descrizione: </b>{PRODUCT[ID].description}</span>
                    <br />
                    <span><b>Step corrente </b>{ProductStage[ID]}</span>
                    <hr />
                    <br />
                    <h5>Prodotto non ancora processato...</h5>
                    <button onClick={() => {
                        showTrackTillOrdered(false);
                    }} className="btn btn-outline-success btn-sm">Track Another Item</button>
                    <span onClick={() => {
                        history.push('/')
                    }} className="btn btn-outline-danger btn-sm"> HOME</span>
                </article>
                
            </div >
        )
    }
    const handlerChangeID = (event) => {
        setID(event.target.value);
    }
    const redirect_to_home = () => {
        history.push('/')
    }
    const handlerSubmit = async (event) => {
        event.preventDefault();
        var ctr = await SupplyChain.methods.productCtr().call();
        if (!((ID > 0) && (ID <= ctr)))
            alert("ID/Lotto non valido!!!");
        else {
            // eslint-disable-next-line
            if (PRODUCT[ID].stage == 3)
                showTrackTillSold(true);
            // eslint-disable-next-line
            else if (PRODUCT[ID].stage == 2)
                showTrackTillRetail(true);
            // eslint-disable-next-line
            else if (PRODUCT[ID].stage == 1)
                showTrackTillDistribution(true);
            // eslint-disable-next-line
            //else if (PRODUCT[ID].stage == 1)
              //  showTrackTillFrantoio(true);
            // eslint-disable-next-line
            
            else
                showTrackTillOrdered(true);

        }
    }

    return (
        <div>
            <span><b>Indirizzo Account corrente:</b> {currentaccount} </span>
            <span onClick={redirect_to_home} className="btn btn-outline-danger btn-sm"> HOME</span>
            <table className="table table-sm table-bordered">
                <thead>
                    <tr>
                        <th scope="col">ID/Lotto</th>
                        <th scope="col">Tipo</th>
                        <th scope="col">Descrizione</th>
                        <th scope="col">Step corrente</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(PRODUCT).map(function (key) {
                        return (
                            <tr key={key}>
                                <td>{PRODUCT[key].id}</td>
                                <td>{PRODUCT[key].name}</td>
                                <td>{PRODUCT[key].description}</td>
                                <td>
                                    {
                                        ProductStage[key]
                                    }
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <h5>Inserisci ID/Lotto del Prodotto per tracciare</h5>

            <form onSubmit={handlerSubmit}>
                <input className="form-control-sm" type="text" onChange={handlerChangeID} placeholder="Inserisci ID/Lotto" required />
                <button className="btn btn-outline-success btn-sm" onSubmit={handlerSubmit}>Track</button>
            </form>
        </div>
    )
}

export default Track
